<template>
  <v-app>
    <Header></Header>
    <v-card
        elevation="10"
        outlined
        class="card"

    >
      <v-list-item-title class="content">
        <h1><b>REFUND POLICY</b></h1>
      </v-list-item-title>
      <br><br>
      <div class="content">
        <span class="card-internal-heading">1. Refund Of Payment Received</span><br>
        For the shop act services that are given to you,
        <router-link to="/" style="text-decoration: none;"><b>shopactreg.in</b></router-link>
        , will only accept refunds if the service is not
        provided.<br>
        <router-link to="/" style="text-decoration: none;"><b>shopactreg.in</b></router-link>
        , reserves the right to determine a fair value of the product on return and the same
        shall be binding on both parties.<br>The refund process will be initiated once we confirmation of the services
        not
        provided. In case of refund request accepted, amount will be refunded in the same mode you have paid. Also if
        there is delay beyond 45 days for submitting hard copy of form, no refund shall be made against such
        transaction.<br><br>

        <span class="card-internal-heading">2. Refund Request</span><br>
        Refund request can be send at contact.shopactreg@gmail.com. Refund request can be made within 10 days of online
        application made.<br><br>

        <span class="card-internal-heading">3. Cancellation Of Application</span><br>
        You cannot cancel the application once processed from our side. No refund will be provided once the application
        is done.<br><br>

        <span class="card-internal-heading">4. Issuance Of Shop Act Licence</span><br>
        Shop act licence applied online, will be delivered within 24 hours on the email id provided in the application
        form.<br><br>

        <span class="card-internal-heading">5. Clarification About Application</span><br>
        If you have any query about application process, you can write us mail at <a
          href="mailto:contact.shopactreg@gmail.com">contact.shopactreg@gmail.com</a> In case
        we need any additional clarification about your shop act application, our team will reach you by email or call.
        <br><br>

        <span class="card-internal-heading">6. Force Majeure</span><br>
        <router-link to="/" style="text-decoration: none;"><b>shopactreg.in</b></router-link>
        shall not be considered in breach of its satisfaction guarantee policy or default under
        any terms of service, and shall not be liable to the client for any cessation, interruption, or delay in the
        performance of its obligations by reason of earthquake, flood, fire, storm, lightning, drought, landslide,
        hurricane, cyclone, typhoon, tornado, natural disaster, act of god or the public enemy, epidemic, famine or
        plague, action of a court or public authority, change in law, explosion, war, terrorism, armed conflict, labor
        strike, lockout, boycott or similar event beyond our reasonable control, whether foreseen or unforeseen (each a
        "Force majeure event").<br><br>

      </div>
    </v-card>
    <br>
    <v-divider></v-divider>
    <br>
    <Footer></Footer>
    <div class="text-center">
    </div>
  </v-app>

</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";

export default {
  name: "refund",
  components: {Footer, Header}
}
</script>

<style scoped>

.content {
  text-align: center;
}

.card {
  max-width: 65vw;
  position: relative;
  left: 18%;
  padding: 10px;
}

.card-internal-heading {
  font-size: larger;
  font-weight: bold;
}

@media only screen and (max-width: 1200px) {
  /*Tablets [601px -> 1200px]*/
}

@media only screen and (max-width: 600px) {

  .card {
    max-width: 95vw;
    position: relative;
    left: 2%;
    padding: 10px;
  }
}

</style>